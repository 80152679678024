@import '../../../../../styles/main.scss';
@import '../../../../../styles/breakpoints';

.osb-v3-preferred-service-centre-wrapper.skeleton {
    .osb-v3-preferred-service-centre {
        margin-top: 25px;
    }
    .skeleton-placeholder {
        height: 20px;
        margin: 10px 0;
        width: 80%;
        animation: shimmer 1.5s infinite linear;
        border-radius: 4px;
        background: linear-gradient(
            to right,
            #e0e0e0 0%,
            #f0f0f0 20%,
            #e0e0e0 40%,
            #e0e0e0 100%
        );
        background-size: 200% 100%;
    }

    .osb-v3-preferred-service-centre-heading.skeleton-placeholder {
        height: 40px;
        width: 60%;
    }

    .osb-v3-preferred-title.skeleton-placeholder,
    .osb-v3-preferred-centre-name.skeleton-placeholder {
        height: 24px;
        width: 100%;
    }
    .osb-v3-preferred-button-container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .skeleton-button {
            height: 40px;
            width: 45%;
            margin-top: 100px;
            background: linear-gradient(
                to right,
                #e0e0e0 0%,
                #f0f0f0 20%,
                #e0e0e0 40%,
                #e0e0e0 100%
            );
            animation: shimmer 1.5s infinite linear;
            border-radius: 4px;
        }
    }
}

@keyframes shimmer {
    0% {
        background-position: -40px 0;
    }
    100% {
        background-position: 40px 0;
    }
}
