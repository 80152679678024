@import '../../../../../styles/main.scss';

.osb-v3-contact-details-form {
    width: 100%;

    .osb-v3-contact-details-form__title {
        color: $fds-color-primary;
        font-family: $f1-font-regular;
        font-size: 40px;
        @include mobile {
            font-size: 32px;
        }
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
    }

    .osb-v3-contact-details-form__label {
        color: $fm-text-grey;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        display: block;
        margin-top: 16px;
    }

    .osb-v3-contact-details-form__input {
        color: $fds-color-black;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        width: 100%;
        padding: 20px;
        margin-top: 4px;
        box-sizing: border-box;
        .fm-dropdown-container .fm-dropdown {
            @include mobile {
                max-width: 100%;
            }
        }
    }
    .fm-input-container {
        max-width: 100%;
        .fm-input {
            max-width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: $fds-color-black;
            border: 1px solid #808080;
            outline: none;
        }
    }
    .osb-v3-contact-number-input {
        width: 100%;
        .fm-input-container {
            max-width: 100%;
            .fm-input {
                max-width: 100%;
                height: 40px;
                font-size: 16px;
                font-weight: 400;
                color: $fds-color-black;
                border: 1px solid #808080;
                outline: none;
            }
        }
    }
    .osb-v3-contact-details-form__row {
        display: flex;
        gap: 16px;
        @include mobile {
            gap: 0;
            flex-direction: column;
        }
    }

    .osb-v3-contact-details-form__column {
        flex: 1;
        &:first-child {
            flex: 0.5;
            max-width: 30%;
        }
        @include mobile {
            max-width: 100%;
            &:first-child {
                max-width: 100%;
            }
        }
    }

    .cdc-custom-combobox-container
        .cdc-custom-combobox-selection
        .cdc-country-name-text {
        height: 40px;
    }

    .osb-v3-contact-details-courtesy-car-info-box {
        max-width: 100%;
        padding: 0 20px;
    }
    .osb-v3-privacy-policy {
        margin-top: 25px;
        max-width: 100%;
        padding: 0 20px;
    }

    .osb-v3-contact-details-form__separator {
        height: 1px;
        background-color: $fds-color-lt-gray-b;
        width: 98%;
        margin: 25px auto;
        @include mobile {
            margin: 25px auto;
            width: 90%;
        }
    }
    .osb-v3-contact-details-form-continue-button {
        margin: 30px 20px;
    }
    .osb-v3-contact-details-form__button {
        display: block;
        padding: 10px 20px;
        background-color: $fds-color-primary;
        color: $white;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-weight: 600;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 16px;
    }
}
