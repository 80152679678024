@import '../../../../../styles/main.scss';
@import '../../../../../styles/breakpoints';
.osb-v3-vehicle-info-skeleton {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 1px solid $fds-color-surface;
    background-color: $fds-color-surface;
    margin-bottom: 20px;

    &-image {
        flex-basis: 50%;
        height: auto;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;

        .osb-v3-vehicle-info-skeleton-default-car-image {
            width: 100%;
            height: auto;
            max-height: 200px;
            object-fit: contain;
        }
    }

    &-details {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $eyebrow-background;
        padding-left: 20px;

        .osb-v3-vehicle-info-skeleton-label,
        .osb-v3-vehicle-info-skeleton-value,
        .osb-v3-vehicle-info-skeleton-textbox,
        .osb-v3-vehicle-info-skeleton-button {
            background: linear-gradient(
                90deg,
                $eyebrow-background 25%,
                $light-gray 50%,
                $eyebrow-background 75%
            );
            background-size: 200% 100%;
            border-radius: 4px;
            margin-bottom: 10px;
            animation: skeleton-loading 1.5s infinite linear;
        }

        .osb-v3-vehicle-info-skeleton-label {
            width: 30%;
            height: 12px;
        }

        .osb-v3-vehicle-info-skeleton-value {
            width: 70%;
            height: 20px;
        }

        .osb-v3-vehicle-info-skeleton-textbox {
            width: 100%;
            height: 40px;
        }

        .osb-v3-vehicle-info-skeleton-button {
            width: 30%;
            height: 40px;
            margin-top: 10px;
        }
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        &-image {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
            min-height: 150px;
        }

        &-details {
            width: 100%;
            border-left: none;
            border-top: 1px solid $eyebrow-background;
            padding-left: 0;
            padding-top: 20px;
        }
    }
}

@keyframes skeleton-loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}
