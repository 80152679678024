@import '../../../../styles/main';

.onboarding-popular-video-tiles-container {
    margin: 1.5rem;

    .onboarding-tile-grid {
        display: grid;
        justify-content: center;
        grid-gap: 30px;
        grid-template-columns: 1fr 1fr 1fr;

        @include mobile {
            grid-template-columns: 1fr;
        }
    }

    .popular-how-to-videos {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 2.5rem;
        border-bottom: 1px solid $fds-color-dk-gray;

        @include mobile {
            .fm-btn__text {
                display: none;
            }
        }

        .popular-how-to-videos-title {
            @include mobile {
                font-size: 18px;
            }
        }
    }
}
