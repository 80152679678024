@import '../../../../../styles/main.scss';
.osb-v3-privacy-text {
    color: $fm-text-grey;
    font-family: $f1-font-regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    margin-top: 16px;
    max-width: 100%;

    &__link {
        color: $fm-breadcrumb-blue;
        cursor: pointer;
    }
}
