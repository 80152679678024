@import '../../../../../styles/main.scss';
.fm-modal.osb-v3-vin-reg-modal-popup {
    @include mobile {
        padding: 0px;
        top: 0;
        bottom: 0;
        margin: 0;
    }
    .fm-modal-content {
        max-width: 960px;
        padding: 0 16px 16px;
        border-radius: 10px;
        @include mobile {
            margin: 0;
            width: 100%;
        }
        .fm-modal-header {
            padding: 16px 16px 0;
            @include mobile {
                padding: 10px 16px 0;
            }
            .fmc-dialog__close {
                margin-left: auto;
                font-size: 18px;
                color: $fm-breadcrumb-blue;
                font-weight: 500;
                width: 24px;
                height: 24px;
            }
        }
    }
    .fm-modal-slot-component {
        font-family: $f1-font-regular;
        padding: 0 16px 16px;
        .vin-title-container {
            display: flex;
            @include mobile {
                flex-direction: column;
            }
            .vin-title {
                font-size: 24px;
                color: $fds-color-primary;
                line-height: 32px;
                font-weight: 500;
            }
            .vin-subtitle {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                color: $fds-color-primary;
                margin-left: 10px;
                @include mobile {
                    margin-left: 0;
                }
            }
        }
        .find-my-vin-info-body-content {
            .vin-subtitle {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: left;
                color: #4d4d4d;
            }
            .vin-details {
                .vin-info-list {
                    list-style: none;
                    display: flex;
                    padding-top: 10px;
                    @include mobile {
                        display: block;
                    }
                    li {
                        padding: 5px 10px;
                        width: 50%;
                        text-align: center;
                        @include mobile {
                            width: 100%;
                            padding: 20px 0px;
                            margin: 0;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                            margin: 20px;
                        }

                        p {
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 24px;
                            text-align: center;
                            color: $fds-color-black;
                        }
                    }
                }
            }
        }
        .modal-line {
            width: 95%;
            margin: 20px auto;
            border: solid 0.5px $fds-color-lt-gray-b;
        }
    }
    .reg-info-container {
        .reg-title-container {
            display: flex;
            @include mobile {
                flex-direction: column;
            }
            .reg-info-title {
                font-size: 24px;
                font-weight: 500;
                line-height: 32px;
                text-align: left;
                color: $fds-color-primary;
            }
            .reg-info-subtitle {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 32px;
                color: $fds-color-primary;
                margin-left: 10px;
                @include mobile {
                    margin-left: 0;
                }
            }
        }

        .reg-content {
            text-align: center;
            img {
                width: 50px;
                height: 50px;
                margin: 20px;
            }
            p {
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                text-align: center;
                color: $fds-color-black;
            }
        }
    }
}
