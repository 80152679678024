@import '../../../../../styles/main.scss';
@import '../../../../../styles/breakpoints';

.osb-v3-search-service-centre-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;

    .osb-v3-search-service-centre-heading {
        color: $fds-color-primary;
        font-family: $f1-font-regular;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .osb-v3-search-service-centre {
        width: 600px;
        background: $fill-on-light;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        margin-top: 25px;
        gap: 0;
        .osb-v3-search-service-back-button {
            align-self: flex-start;
            margin-left: 10px;
            .fm-btn {
                justify-content: center;
            }
            .fm-btn__text {
                margin-left: -10px;
            }
        }
        .osb-v3-search-title {
            font-family: $f1-font-regular;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            color: $fds-color-primary;
            padding: 10px;
            width: 100%;
        }

        .osb-v3-search-input {
            width: 75%;
            padding: 10px;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 24px;
            color: $fm-text-grey;
        }

        .osb-v3-use-my-location-link {
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-align: left;
            color: $fm-breadcrumb-blue;
            padding: 10px;
            width: 100%;
            cursor: pointer;
            text-decoration: underline;
            margin-bottom: 10px;
            position: relative;
            padding-left: 40px;
            &::before {
                content: url('../../../../../assets/osb-v3/navigation.svg');
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .osb-v3-search-service-centre-button-container {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            gap: 15px;

            .osb-v3-search-service-centre-continue-button,
            .osb-v3-search-service-centre-button {
                padding: 10px 20px;
                font-size: 16px;
                margin-top: 10px;
                cursor: pointer;
                &:disabled {
                    cursor: not-allowed;
                    background-color: $eyebrow-background;
                    color: $eyebrow-color;
                    opacity: 0.6;
                }
            }
        }
    }
}

@include mobile {
    .osb-v3-search-service-centre-wrapper {
        text-align: left;
        .osb-v3-search-service-centre-heading {
            font-size: 28px;
            line-height: 36px;
            align-self: stretch;
        }
        .osb-v3-search-service-centre {
            width: 100%;
            padding-left: 5px;
            .osb-v3-search-input {
                width: 100%;
            }
            .osb-v3-search-service-centre-button-container {
                flex-direction: column;
                gap: 5px;
                .osb-v3-search-service-centre-continue-button,
                .osb-v3-search-service-centre-button {
                    padding: 10px 20px;
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    text-align: center;
                }
                .fm-btn {
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}
