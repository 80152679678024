@import '../../../../../styles/main.scss';
.comment-input-container {
    width: 100%;

    textarea {
        width: 100%;
        height: 100px;
        box-sizing: border-box;
        resize: none;
        color: $fm-text-grey;
        font-family: $f1-font-regular, sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        padding: 12px;
        border: 1px solid $fm-text-grey;
        border-radius: 10px;
        outline: none;
    }

    .characters-remaining {
        text-align: right;
        font-size: 14px;
        margin-top: 4px;
    }
}
