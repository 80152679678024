@import '../../../../styles/main.scss';

.osb-v3-vehicle-step-component {
    width: 100%;
    margin: auto;
    padding: 30px;
    font-family: $f1-font-regular;
    @include mobile {
        padding: 10px;
    }
    .osb-v3-title {
        font-size: 40px;
        font-weight: 400;
        color: $fds-color-primary;
        text-align: center;
        @include mobile {
            font-size: 32px;
            margin: 20px auto;
            line-height: 40px;
        }
    }
    .osb-v3-vehicle-step-component-section {
        display: flex;
        padding: 30px;
        width: 80%;
        margin: 30px auto;
        background-color: $fill-on-light;
        border-radius: 10px;
        flex-wrap: wrap;
        align-content: stretch;
        justify-content: center;
        @include mobile {
            width: 100%;
            margin: 0px;
            display: block;
            padding: 20px;
        }
        .separator {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            position: relative;
            flex-direction: column;
            height: auto;

            &::before,
            &::after {
                content: '';
                flex: 1;
                width: 1px;
                background: $fds-color-lt-gray-b;
                position: absolute;
                height: 100%;
            }

            span {
                z-index: 1;
                padding: 5px;
                background-color: $fill-on-light;
                color: $fds-color-primary;
                font-size: 24px;
                font-weight: 500;
            }
        }
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
            width: 100%;
            .separator {
                margin: 20px 0;
                width: 100%;
                height: auto;
                position: relative;

                &::before,
                &::after {
                    width: 100%;
                    height: 1px;
                }
            }
        }
    }
    .osb-v3-retrieve-booking-section {
        display: flex;
        margin: 40px auto 0 auto;
        width: 100%;
        padding: 30px 30px 0 30px;
        align-items: center;
        justify-content: center;
        gap: 30px;
        border-top: 1px solid $eyebrow-background;
        @include mobile {
            width: 100%;
            margin: 0px;
            display: block;
            padding: 20px;
            border: none;
        }
        .osb-v3-retrieve-booking-desc {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .osb-v3-retrieve-cta-section {
            @include mobile {
                margin: 20px auto;
            }
            .fm-btn__light {
                @include mobile {
                    width: 100%;
                    display: block;
                }
            }
        }
    }
}
