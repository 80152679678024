.brand-ford {
    .onboarding-popular-video-wrapper {
        .onboarding-popular-video-tile {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;
            transition: 0.5s;
            text-align: center;
            row-gap: 10px;
            position: relative;

            .onboarding-video-poster {
                &:before {
                    content: '';
                    position: absolute;
                    top: -30px;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: transparent
                        url('../../../../../assets/play.png') center center
                        no-repeat;
                    background-size: 100px;

                    @include tablet {
                        background-size: 60px;
                    }

                    @include desktop {
                        background-size: 100px;
                    }
                }

                &:hover:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 10;
                    background: transparent
                        url('../../../../../assets/play.png') center center
                        no-repeat;
                    background-size: 120px;

                    @include tablet {
                        background-size: 70px;
                    }

                    @include desktop {
                        background-size: 120px;
                    }
                }
            }

            .onboarding-vjs-poster {
                width: 100%;
                object-fit: contain;
            }
        }
    }
}
