@import '../../../../../styles/main.scss';
.osb-v3-pickup-and-delivery-contact-details-form {
    width: 100%;
    padding: 20px 20px;
    margin-top: 4px;
    .osb-v3-pickup-and-delivery-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .osb-v3-pickup-and-delivery-contact-label {
            color: #333;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
        }

        .osb-v3-pickup-and-delivery-edit-link {
            color: #0562d2;
            text-align: right;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            text-decoration: underline;
        }
    }

    .osb-v3-pickup-and-delivery-contact-info {
        .osb-v3-pickup-and-delivery-name,
        .osb-v3-pickup-and-delivery-phone,
        .osb-v3-pickup-and-delivery-email {
            color: #333;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
        }
    }
    .fm-input-container {
        max-width: 100%;
        .fm-input {
            max-width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: $fds-color-black;
            //margin-bottom: 20px;
            border: 1px solid #808080;
        }
    }
    .osb-v3-pickup-delivery-code {
        max-width: 60%;
        margin-bottom: 0;
        // .fm-input-container {
        //     .fm-input {
        //         margin-bottom: 0;
        //     }
        // }
    }
    .osb-v3-pickup-and-delivery-divider {
        height: 1px;
        background-color: #b2b2b2;
        margin: 30px 0;
    }

    .osb-v3-pickup-delivery-title {
        color: #00095b;
        font-family: $f1-font-regular;
        font-size: 32px;
        @include mobile {
            font-size: 28px;
        }
        font-weight: 400;
        line-height: 40px;
    }
    .osb-v3-pickup-delivery-checkbox {
        width: fit-content;
        color: #333;
        font-family: $f1-font-regular;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-bottom: 25px;
        margin-top: 25px;
        .fmc-check label {
            span {
                margin-left: 10px;
            }
            @include mobile {
                line-height: 20px;
            }
        }
        .fmc-check input[type='checkbox'] {
            --button-outline-color: blue;
            @include mobile {
                width: 20px;
                height: 20px;
            }
        }

        .fmc-check input[type='checkbox']:checked {
            --fill-color: blue;
            --button-outline-color: blue;
        }
        .fmc-check input[type='checkbox']:checked::before {
            color: #ffffff;
        }
    }

    .osb-v3-pickup-and-delivery-address-fields {
        .osb-v3-pickup-and-delivery-address1 {
            margin-bottom: 15px;
        }
        .osb-v3-pickup-and-delivery-address2 {
            margin-bottom: 15px;
        }
        .osb-v3-pickup-and-delivery-address3 {
            margin-bottom: 15px;
        }
        .osb-v3-pickup-and-delivery-town {
            margin-bottom: 15px;
        }
        .osb-v3-pickup-and-delivery-parking-type {
            margin-bottom: 15px;
            .fm-dropdown-container .fm-dropdown {
                @include mobile {
                    max-width: 100%;
                }
            }
        }

        label {
            color: #333;
            font-family: $f1-font-regular;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        input {
            color: #000;
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            display: block;
            outline: none;
        }
        .osb-v3-pickup-and-delivery-search-link {
            color: #0562d2;
            font-family: $f1-font-regular;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-decoration: underline;
        }
        .osb-v3-pickup-delivery-postcode {
            margin-bottom: 20px;
            .options-list {
                max-width: 320px;
                @include mobile {
                    width: 100%;
                    max-width: 100%;
                }
            }
            .fm-input-container {
                max-width: 320px;
                @include mobile {
                    max-width: 100%;
                }
                .fm-input {
                    margin-bottom: 0;
                }
            }
        }
    }

    .osb-v3-Pnd-privacy-policy {
        margin-top: 25px;
        max-width: 100%;
    }

    .osb-v3-command-button {
        background-color: #0562d2;
        color: #fff;
        font-family: $f1-font-regular;
        font-size: 16px;
        padding: 10px 20px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        display: block;
        margin: 20px 0;
    }
    .osb-v3-Pnd-error-message {
        margin-top: 5px;
        color: red;
        font-family: $f1-font-regular;
        font-size: 14px;
    }
}
