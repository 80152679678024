@import '../../../../styles/main';

.time {
    width: 100%;
    margin: auto;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #f2f2f2;
    @include desktop {
        width: 100%;
        height: 72px;
        display: inline-flex;
    }
    .mobile-inline {
        display: inline-flex;
    }
    .time-text {
        font-family: $f1-font-regular;
        margin-left: 18px;
        margin-right: 10px;
        padding-top: 19px;
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        color: $fds-color-dk-gray;

        @include tablet {
            padding-bottom: 19px;
            margin-left: 24px;
        }
        @include tablet-portrait() {
            margin-left: 24px;
        }
        @include mobile() {
            margin-left: 24px;
        }
        @include desktop {
            margin-left: 18px;
            margin-right: 157px;
            padding-top: 22px;
            padding-bottom: 19px;
            display: inline-flex;
            height: 26px;
        }
        @media (min-width: 992px) and (max-width: 1100px) {
            display: initial;
        }
        .upper-case {
            text-transform: uppercase;
            color: $dyf-color-darkblue;
        }
    }
    .countdown-banner-cta {
        display: inline-flex;
        padding-bottom: 10px;
        height: 54px;
        width: 162px;

        @media (min-width: 700px) and (max-width: 991px) {
            padding-top: 5px;
            right: 10px;
            padding-bottom: 5px;
            margin-left: 0;
        }
        @include desktop {
            position: absolute;
            padding-top: 5px;
            right: 20px;
            padding-bottom: 5px;
            margin-left: 0;
            margin-top: 12px;
        }
        @include l-desktop {
            right: 100px;
        }
        .fds-chevron .fds-arrow.filled {
            margin-left: -1rem;
            color: white;
        }
    }
}
