.brand-ford {
    .htv-video-container {
        margin: 10px auto 0 auto;
        width: 90%;
        height: 100%;

        @include xl-desktop {
            margin: 10px auto 0px auto;
            width: 80%;
        }

        .video-js {
            height: 100% !important;
            width: 100% !important;
            video {
                object-fit: contain !important;
            }
        }

        .video-desc-container {
            color: #4d4d4d;
            font-family: $f1-font-light;
            font-size: 1rem;
            text-align: center;
            width: 90%;
            margin: 5% 5% 10% 5%;
            line-height: 20px;
            max-height: 60px;

            @include tablet {
                width: 60%;
                margin: 20px 20% 20px 20%;
                line-height: 26px;
            }
        }

        .vjs-big-play-button {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-color: transparent !important;
            background-image: url('../../../../assets/play.png');
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 0;
            padding: 2px;
            width: 6rem;
            height: 6rem;
            .vjs-icon-placeholder {
                display: none;
            }
        }
        .discover-section {
            margin: 0 !important;
        }
    }
}
