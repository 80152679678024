.brand-lincoln {
    .htv-video-container {
        margin: 10px auto 0 auto;
        width: 90%;
        height: 100%;

        @include xl-desktop {
            margin: 10px auto 0px auto;
            width: 80%;
        }

        .video-js {
            height: 100% !important;
            width: 100% !important;
            video {
                object-fit: contain !important;
            }
        }

        .video-desc-container {
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            font-size: 1rem;
            text-align: center;
            width: 95%;
            letter-spacing: 0px;
            margin: 5% 5% 10% 5%;
            line-height: 20px;
            max-height: 60px;

            @include tablet {
                width: 80%;
                font-size: 1rem;
                letter-spacing: 1px;
                margin: 20px auto;
                line-height: 26px;
            }
            @include desktop {
                width: 60%;
                font-size: 1.125rem;
                margin: 20px 20% 20px 20%;
            }
        }

        .vjs-big-play-button {
            margin: 0;
            position: absolute;
            top: 50%;
            left: 50%;
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            background-color: transparent !important;
            background-image: url('../../../../assets/lincoln-play.png');
            background-repeat: no-repeat;
            background-size: contain;
            border-radius: 0;
            padding: 2px;
            width: 6rem;
            height: 6rem;
            .vjs-icon-placeholder {
                display: none;
            }
        }
        .discover-section {
            margin: 0 !important;
        }
    }
}
