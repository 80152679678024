@import '../../../../../styles/main.scss';
@import '../../../../../styles/breakpoints';
.osb-v3-preferred-service-centre-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;

    .osb-v3-preferred-service-centre-heading {
        color: $fds-color-primary;
        font-family: $f1-font-regular;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 48px;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .osb-v3-preferred-service-centre {
        width: 600px;
        background: $fill-on-light;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 25px;
        gap: 0;

        .osb-v3-preferred-title {
            font-family: $f1-font-regular;
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            color: $fds-color-primary;
            padding: 10px;
            width: 100%;
        }

        .osb-v3-preferred-centre-name {
            font-family: $f1-font-regular;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            text-align: left;
            color: $fm-text-grey;
            padding: 10px;
            width: 100%;
            margin-bottom: 55px;
        }
        .osb-v3-preferred-back-button {
            align-self: flex-start;
            margin-left: 10px;
            .fm-btn {
                justify-content: center;
            }
            .fm-btn__text {
                margin-left: -10px;
            }
        }
        .osb-v3-preferred-button-container {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;

            .osb-v3-preferred-continue-button,
            .osb-v3-preferred-search-button {
                padding: 10px 10px;
                font-size: 16px;
                margin-top: 10px;
                cursor: pointer;
            }
        }
    }
}

@include mobile {
    .osb-v3-preferred-service-centre-wrapper {
        text-align: left;
        .osb-v3-preferred-service-centre-heading {
            font-size: 28px;
            line-height: 36px;
            align-self: stretch;
        }
        .osb-v3-preferred-service-centre {
            width: 100%;
            padding-left: 5px;
            .osb-v3-preferred-centre-name {
                margin-bottom: 20px;
            }
            .osb-v3-preferred-button-container {
                flex-direction: column;
                .osb-v3-preferred-continue-button,
                .osb-v3-preferred-search-button {
                    padding: 5px 10px;
                    width: 100%;
                    margin-left: 0;
                    margin-right: 0;
                    text-align: center;
                }
                .osb-v3-preferred-search-button {
                    margin-top: 0;
                }
                .fm-btn {
                    justify-content: center;
                    width: 100%;
                }
            }
        }
    }
}
