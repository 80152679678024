@import '../../../../../styles/main.scss';
.osb-v3-ford-account-login-container {
    flex: 40%;
    @include mobile {
        width: 100%;
    }
    .ford-account-section-title {
        font-size: 24px;
        color: $fds-color-primary;
        line-height: 32px;
        font-weight: 500;
    }
    .ford-account-section-desc {
        font-size: 14px;
        color: $fds-color-primary;
        line-height: 20px;
        font-weight: 500;
    }

    .login-btn {
        width: 100%;
        @include mobile {
            margin: 20px 0;
        }
        .fm-btn__light {
            width: 90%;
            display: block;
            @include mobile {
                width: 100%;
            }
        }
    }
    .osb-dealer-login-container {
        .login-instruction {
            margin: 50px 0;
            @include mobile {
                padding: 0;
                margin: 20px 0;
            }
            ul {
                list-style-position: outside;
                padding: 0 5px;
                li {
                    list-style-type: none;
                    color: $fm-text-grey;
                    font-family: $f1-font-regular;
                    font-size: 16px;
                    line-height: 24px;
                    padding: 0 5px;
                    margin: 10px 0;
                    font-weight: 300;
                    @include mobile {
                        font-size: 14px;
                        margin: 0;
                        font-weight: 400;
                        line-height: 20px;
                    }
                    &::marker {
                        content: '\2022';
                        color: $fds-color-primary;
                        padding: 0 5px;
                        margin: 20px;
                        width: 1em;
                    }
                }
            }
        }
    }
}
