.brand-lincoln {
    .how-to-top-text {
        background-size: cover;
        padding-top: 64px;
        height: 355px;
        width: 100%;

        @include desktop {
            background-size: cover;
            padding-top: 104px;
            height: 335px;
            width: 100%;
            margin: 0;
        }

        .how-to-title {
            width: 100%;
            font-family: $lincoln-millerb-black;
            font-size: 40px;
            font-weight: 900;
            letter-spacing: 0;
            line-height: 44px;
            text-align: center;
            margin: none;
            text-transform: capitalize;
            color: $white;

            @include desktop {
                width: 100%;
                font-size: 48px;
                font-weight: 900;
                letter-spacing: 0;
                line-height: 58px;
            }
        }

        .how-to-description {
            height: 104px;
            width: 100%;
            color: $white;
            font-family: $proxima-nova-regular;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 26px;
            text-align: center;
            margin-top: 24px;

            @include desktop {
                text-align: center;
                height: 52px;
                width: 100%;
            }
            .description-text {
                margin-left: 5%;
                margin-right: 5%;

                @include tablet {
                    margin-left: 15%;
                    margin-right: 15%;
                }

                @include l-desktop {
                    margin-left: 32%;
                    margin-right: 32%;
                }
            }
        }
    }
}
