.brand-lincoln {
    .popular-how-to-video-tiles-container {
        margin: 110px 5% 0 5%;
        width: 90%;

        @include xl-desktop {
            width: 80%;
            margin: 110px 10% 0 10%;
        }

        .popular-how-to-videos {
            height: 50px;
            color: $brand-secondary;
            font-family: $proxima-nova-regular;
            border-bottom: 1px solid $brand-secondary;
            letter-spacing: 2px;
            font-size: 17px;
            margin-bottom: 20px;
            margin-top: 44px;

            .popular-how-to-video-title {
                line-height: 27px;
                margin-bottom: 16px;
                font-size: 17px;
                letter-spacing: 2px;
                font-family: $proxima-nova-regular;
                text-align: left;
                padding-bottom: 0;
                padding-top: 10px;
                text-transform: capitalize;

                @include tablet {
                    grid-template-columns: 1fr 1fr 1fr;
                    font-size: 20px;
                    letter-spacing: 2.25px;
                    line-height: 43px;
                }
            }
        }

        .tile-grid {
            display: grid;
            justify-content: center;
            grid-gap: 32px;
            grid-template-columns: 1fr;

            @include tablet {
                min-height: 180px;
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include desktop {
                min-height: 290px;
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include xl-desktop {
                grid-template-columns: 1fr 1fr 1fr;
                min-height: 320px;
                grid-gap: 44px;
            }
        }
    }
}
