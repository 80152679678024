@import '../../../../../styles/main.scss';

.fm-modal.osb-v3-vehicle-detail-info {
    .fm-modal-content {
        padding: 0 16px 16px !important;
        border-radius: 5px;
        max-width: 600px;
        @include mobile {
            margin: 0 auto;
            width: 90%;
        }
        .fm-modal-header {
            padding: 16px 16px 0 !important;
            @include mobile {
                padding: 10px 16px 0;
            }
            .fmc-dialog__close {
                margin-left: auto;
                font-size: 18px;
                color: $fm-breadcrumb-blue;
                font-weight: 500;
                width: 24px;
                height: 24px;
            }
        }
    }
    .fm-modal-slot-component {
        padding: 0 16px 16px;
    }
    .osb-v3-vehicle-detail-info-container {
        font-family: $f1-font-regular;
        .osb-v3-vehicle-detail-info-section {
            .osb-v3-vehicle-detail-info-title {
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                color: $fds-color-primary;
                @include mobile {
                    font-size: 20px;
                }
            }
            .osb-v3-vehicle-model-and-year {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                color: $darkgrey;
                text-transform: capitalize;
            }
            .osb-v3-vehicle-detail-desc {
                font-size: 12px;
                text-align: left;
                color: $fm-text-grey;
                margin: 10px 0;
            }
            .osb-v3-vehicle-detail-info-other-section {
                display: flex;
                margin-bottom: 20px;
                @include mobile {
                    display: block;
                }
                .osb-v3-vehicle-detail-info-content {
                    width: 50%;
                    .osb-v3-vehicle-detail-info-item {
                        margin: 20px 0;
                        .osb-v3-registration-number-label,
                        .osb-v3-vin-label,
                        .osb-v3-mileage-label {
                            font-size: 12px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 16px;
                            color: $darkgrey;
                        }
                        .osb-v3-registration-number-value,
                        .osb-v3-vin-value,
                        .osb-v3-mileage-value {
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 28px;
                            color: $fm-text-grey;
                        }
                    }
                }
                .osb-v3-vehicle-detail-info-image {
                    width: 50%;
                    @include mobile {
                        width: 100%;
                        margin: 30px 0;
                    }
                    img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
        .osb-v3-vehicle-detail-cta-section {
            border-top: 1px solid #ccc;
            padding-top: 20px;
            display: flex;
            column-gap: 10px;
            .osb-v3-vehicle-detail-save-button,
            .osb-v3-vehicle-detail-no-thanks-button {
                @include mobile {
                    width: 50%;
                }
                .fm-btn__light {
                    @include mobile {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}
