@import '../../../../styles/main.scss';
.osb-v3-cancel-amend-confirmation-view-container {
    padding: 100px 168px;
    @include mobile {
        padding: 60px 16px;
    }
    .osb-v3-cancel-amend-confirmation-component {
        background-color: $fill-on-light;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 36px 32px;
        @include mobile {
            display: block;
            padding: 36px 28px;
        }
        .osb-v3-cancel-amend-heading-wrapper {
            width: 100%;
            .osb-v3-cancel-amend-message-title-wrapper {
                font-family: $f1-font-regular;
                font-weight: 400;
                font-size: 32px;
                line-height: 40px;
                color: $fm-danger;
                padding-bottom: 24px;
                @include mobile {
                    font-size: 28px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 36px;
                }
            }
            .osb-v3-cancel-amend-message-divider {
                border: 1px solid;
                border-color: $fds-color-lt-gray-b;
            }
            .osb-v3-cancel-amend-subtitle-wrapper {
                padding-top: 24px;
                .osb-v3-sercice-centre-confirmation-text {
                    font-family: $f1-font-medium;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 32px;
                    color: $fds-color-primary;
                    @include mobile {
                        padding-bottom: 8px;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 32px;
                    }
                }
                .osb-v3-cancel-amend-location-time-message {
                    font-family: $f1-font-regular;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    color: $fm-text-grey;
                    @include mobile {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
            }
        }
        .osb-v3-log-in-or-create-booking-option-section {
            padding-top: 60px;
            display: flex;
            justify-content: space-between;
            @include mobile {
                display: flex;
                flex-direction: column;
            }
            .osb-v3-log-in-account-option-wrapper {
                padding-top: 15px;
                width: 427px;
                @include mobile {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 12px;
                }
                .osb-v3-log-in-account-option-button {
                    @include mobile {
                        width: 100%;
                        justify-content: center;
                    }
                }
                .osb-v3-registration-prompt-wrapper {
                    padding-top: 48px;
                    .osb-v3-registration-prompt-heading {
                        font-family: $f1-font-regular;
                        font-size: 24px;
                        font-weight: 500;
                        list-style: 32px;
                        color: $fds-color-primary;
                        padding-bottom: 12px;
                    }
                    .osb-v3-registration-prompt-list {
                        padding-left: 20px;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        line-height: 24px;
                        color: $fm-text-grey;
                    }
                }
            }
            .osb-v3-log-in-or-create-booking-option-divider {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0 84px;
                @include mobile {
                    padding: 24px 0;
                    flex-direction: row;
                }
                .osb-v3-log-in-or-create-booking-option-divider-line {
                    border: 1px solid;
                    border-color: $fds-color-lt-gray-b;
                    width: 1px;
                    height: 100%;
                    @include mobile {
                        height: 1px;
                        width: 100%;
                    }
                }
                .osb-v3-log-in-or-create-booking-option-divider-text {
                    font-size: 24px;
                    font-family: $f1-font-regular;
                    font-weight: 500;
                    color: $fds-color-primary;
                    padding-top: 16px;
                    padding-bottom: 8px;
                    @include mobile {
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 24px;
                        padding: 0 16px;
                    }
                }
            }
            .osb-v3-create-booking-option-wrapper {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: $f1-font-regular;
                h3 {
                    font-size: 24px;
                    font-weight: 500;
                    padding-bottom: 16px;
                    color: $fds-color-primary;
                }
                p {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }
}
