@import '../../../../styles/breakpoints';
@import '../../../../styles/main.scss';

.osb-v3-step-progress-bar-container {
    margin: 0 auto;
    width: 100%;
    position: sticky;
    background-color: $fds-color-white;
    z-index: 101;

    .step-progress-bar {
        padding: 47px 0 39px;
        text-align: center;
        width: 70%;
        margin: 0 auto;

        @include tablet-portrait {
            width: 80%;
        }

        li {
            display: inline-block;
            width: calc(100% / 5);
            @media (min-width: 769px) {
                width: calc(80% / 5);
            }
            text-align: center;
            position: relative;
            color: $fds-color-primary;
            font-size: 16px;
            list-style: none;
            vertical-align: top;
            margin: 0;

            @include tablet-portrait {
                font-size: 12px;
            }

            // Centering the connecting line
            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 60%;
                width: 50%;
                height: 2px;
                background-color: $fds-color-md-gray;
                transform: translateY(-50%);
                z-index: -1;
            }

            &::before {
                left: 0;
            }

            &::after {
                right: 0;
            }

            &:first-child::before {
                content: none;
            }

            &:last-child::after {
                content: none;
            }

            &.active::before,
            &.active::after,
            &.changed::before,
            &.changed::after {
                background-color: $fm-skyview;
            }
        }

        li.desktop-step-width-1 {
            width: 200px;
        }

        li.desktop-step-width-default {
            width: 165px;
        }

        li.mobile-step-width-1 {
            width: 55%;
        }

        li.mobile-step-width-default {
            width: 45%;
        }

        li .step-count-outer {
            border: 2px $fds-color-white solid;
            border-radius: 50%;
            height: 36px;
            width: 36px;
            display: block;
            text-align: center;
            margin: 0 auto;
            background-color: $fds-color-white;
            position: relative;

            .step-count {
                height: 28px; // Adjust inner circle size
                width: 28px;
                line-height: 28px;
                font-size: 13px;

                border: 2px $fds-color-md-gray solid;
                border-radius: 50%;
                display: block;
                margin: 5px auto;
                background-color: $fds-color-white;
                color: $fds-color-dk-gray;
                font-weight: bold;
                position: relative;
                z-index: 1;
                @media (max-width: 768px) {
                    height: 31px;
                    width: 31px;
                    line-height: 31px;
                    font-size: 12px;
                }
            }
        }
        li .step-label {
            font-size: 16px;
            color: $fds-color-primary;
            margin-top: 10px;
            font-family: $f1-font-regular;
            line-height: 24px;
            text-align: center;
            white-space: nowrap;
            &.mobile-active-label {
                font-weight: bold;
                @media screen and (max-width: 768px) {
                    line-height: 0;
                    margin-top: 15px;
                }
            }
        }
        li.active .step-label {
            font-weight: bold;
        }
        li.active .step-count-outer {
            cursor: pointer;

            .step-count {
                border-color: $fm-skyview;
                color: $fds-color-white;
                background-color: $fm-skyview;
            }

            .step-count:hover {
                background-color: $background-dark-blue;
                border-color: $background-dark-blue;
                color: $fds-color-white;
                box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
                    0 2rem 2rem 0 rgba(black, 0.1);
            }
        }

        li.changed .step-count-outer {
            cursor: pointer;

            .step-count {
                background-color: $fds-color-white;
                border-color: $fm-skyview;
                background-size: 20px 20px;
                background-repeat: no-repeat;
                background-position: center;
                content: url(../../../../assets/osb-v3/progress-tick-mark.svg);
            }

            .step-count:hover {
                border-color: $background-dark-blue;
                background-color: $background-dark-blue;
                color: $fds-color-white;
                content: url(../../../../assets/osb-v3/progress-white-tick-mark.svg);
                box-shadow: 0 1rem 1rem 0 rgba(black, 0.1),
                    0 2rem 2rem 0 rgba(black, 0.1);
            }
        }

        li.editable .step-count-outer {
            cursor: pointer;
        }
    }

    .step-progress-bar::-webkit-scrollbar {
        display: none;
    }

    // Responsive adjustments for tablet
    @media (max-width: 768px) {
        .step-progress-bar {
            padding: 28px 0 20px;
            overflow-x: scroll;
            white-space: nowrap;
            width: 80%;
        }

        li {
            font-size: 12px;

            &::before,
            &::after {
                width: 70%;
            }

            .step-count-outer {
                height: 36px;
                width: 36px;

                .step-count {
                    height: 28px;
                    width: 28px;
                    line-height: 28px;
                    font-size: 13px;
                }
            }
        }
    }

    // Responsive adjustments for mobile
    @media (max-width: 480px) {
        .step-progress-bar {
            padding: 28px 0 30px;
            overflow-x: scroll;
            white-space: nowrap;
            width: 100%;
        }
        li {
            &::before,
            &::after {
                width: 70%;
            }

            &.active::before,
            &.active::after,
            &.changed::before,
            &.changed::after {
                background-color: $fm-skyview;
            }
        }
    }
}
