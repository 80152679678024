@import '../../../../../styles/main.scss';
@import '../../../../../styles/breakpoints';
.osb-v3-vehicle-info {
    display: flex;
    align-items: center;
    padding: 20px;
    border: 2px solid $fill-on-light;
    background-color: $fill-on-light;
    margin-bottom: 20px;
    font-family: $f1-font-regular;
    transition: border-color 0.3s ease;

    &:hover,
    &:focus-within {
        border: 3px solid $fm-breadcrumb-blue;
    }

    &-image {
        flex-basis: 50%;
        height: auto;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 200px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    &-details {
        flex-basis: 50%;
        display: flex;
        flex-direction: column;
        border-left: 1px solid $eyebrow-background;
        padding: 10px 0 10px 20px;
    }

    &-item {
        margin-bottom: 20px;
        .osb-v3-vehicle-name-label,
        .osb-v3-registration-number-label,
        .osb-v3-vin-label,
        .osb-v3-mileage-label {
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            text-align: left;
            color: $darkgrey;
        }
        .osb-v3-vehicle-name-value,
        .osb-v3-registration-number-value,
        .osb-v3-vin-value {
            font-size: 20px;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
            color: $fm-text-grey;
        }
        .osb-v3-vehicle-name-value {
            font-weight: 500;
            font-family: $f1-font-medium;
            font-size: 24px;
        }

        .fm-input-container,
        .fm-input {
            max-width: 430px;
            height: 40px;
            font-size: 16px;
            font-weight: 400;
            color: $darkgrey;
        }
        .fm-input {
            padding: 0 10px;
        }
        .osb-v3-vehicle-info-mileage-error-message {
            color: $error-message-text;
            font-size: 12px;
            margin-top: 5px;
        }
    }

    &-input {
        padding: 5px;
        width: 100%;
    }

    &-continue-button {
        button {
            height: 40px;
            font-size: 14px;
            @include mobile {
                display: block;
                width: 100%;
            }
            &::after {
                background-color: $fm-skyview;
            }
        }
    }

    // Media query for mobile view
    @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        &-image {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
            min-height: 150px;
        }

        &-details {
            width: 100%;
            border-left: none;
            border-top: 1px solid $eyebrow-background;
            padding-left: 0;
            padding-top: 20px;
        }
    }
}
