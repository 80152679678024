.brand-ford {
    .popular-how-to-video-tiles-container {
        margin: 110px 5% 0px 5%;
        width: 90%;

        @include xl-desktop {
            width: 80%;
            margin: 110px 10% 0px 10%;
        }

        .popular-how-to-videos {
            height: 70px;
            color: #4d4d4d;
            margin-bottom: 20px;
            margin-top: 44px;

            .popular-how-to-video-title {
                line-height: 28px;
                margin-bottom: 16px;
                font-size: 20px;
                font-family: $f1-font-light;
                text-align: left;
                border-bottom: 1px solid #4d4d4d;
                padding-bottom: 0px;
                padding-top: 30px;
                text-transform: capitalize;

                @include tablet {
                    grid-template-columns: 1fr 1fr 1fr;
                    font-size: 20px;
                    line-height: 43px;
                }
            }
        }

        .tile-grid {
            display: grid;
            justify-content: center;
            grid-gap: 32px;
            grid-template-columns: 1fr;

            @include tablet {
                min-height: 180px;
                grid-template-columns: 1fr 1fr 1fr;
            }
            @include desktop {
                min-height: 290px;
                grid-template-columns: 1fr 1fr 1fr;
            }

            @include xl-desktop {
                grid-template-columns: 1fr 1fr 1fr;
                min-height: 320px;
                grid-gap: 44px;
            }
        }
    }
}
