@import '../../../../styles/main.scss';

.osbV3bookingConfirmationWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    @include mobile {
        padding: 16px 16px 0px 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 40px;
        align-self: stretch;
    }
    .bookingConfirmationContentWrapper {
        display: flex;
        padding: 64px 0px 64px 108px;
        align-items: flex-start;
        gap: 16px;
        @include mobile {
            padding: 0;
            align-self: stretch;
        }
        .bookingConfirmationInnerContentWrapper {
            display: flex;
            padding: 0px;
            flex-direction: column;
            align-items: flex-start;
            gap: 40px;
            .bookingConfirmationtitleWrapper {
                color: $fds-color-primary;
                font-family: $f1-font-regular;
                font-size: 40px;
                line-height: 48px;
                @include mobile {
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 40px;
                }
            }
            .bookingConfirmationDetailsWrapper {
                display: flex;
                padding-right: 16px;
                flex-direction: column;
                align-items: flex-start;
                gap: 32px;
                @include mobile {
                    gap: 24px;
                    align-self: stretch;
                }
                .bookingConfirmationDescriptionWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 16px;
                    align-self: stretch;
                    @include mobile {
                        padding: 0;
                    }
                    .bookingConfirmationDescriptionSubheading {
                        align-self: stretch;
                        color: $fm-text-grey;
                        font-family: $f1-font-regular;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 32px;
                    }
                    .bookingConfirmationDescription {
                        align-self: stretch;
                        color: $fm-text-grey;
                        font-family: $f1-font-regular;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
                .bookingConfirmationDivider {
                    width: 100%;
                    height: 1px;
                    background: $fds-color-lt-gray-b;
                }
                .manageBookingWrapper {
                    .manageBookingContentWrapper {
                        display: flex;
                        padding-right: 16px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 32px;
                        @include mobile {
                            gap: 16px;
                        }
                        .manageBookingHeadingWrapper {
                            color: $fm-text-grey;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 32px;
                            align-self: stretch;
                        }
                        .manageBookingDescriptionWrapper {
                            color: $fm-text-grey;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;
                        }
                    }
                }
                .fordAccountWrapper {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 12px;
                    align-self: stretch;
                    .fordAccountSubheadingWrapper {
                        color: $fm-text-grey;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 32px;
                    }
                    .fordAccountDescriptionWrapper {
                        color: $fm-text-grey;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                    }
                }
                .fordAccountButtonWrapper {
                    display: flex;
                    align-items: center;
                    gap: 24px;
                    align-self: stretch;
                    @include mobile {
                        button {
                            flex: 1 0;
                            justify-content: center;
                        }
                    }
                }
            }
        }
    }
    .bookingConfirmationimageContentWrapper {
        @include mobile {
            display: flex;
            padding-bottom: 40px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 22px;
        }
    }
}
