@import '../../../../../styles/main.scss';
.osb-v3-info-box {
    display: flex;
    max-width: 100%;
    padding: 16px;
    justify-content: flex-start;
    align-items: flex-start;
    border-radius: 8px;
    margin-top: 32px;

    &__icon {
        margin-right: 16px;
    }

    &__title {
        color: $fm-text-grey;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    &__text {
        color: $fm-text-grey;
        font-family: $f1-font-regular;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        margin-top: 8px;
    }
}
