@import '../../../../../styles/main.scss';
.osb-v3-vehicle-search-container {
    flex: 40%;
    margin-left: auto;
    @include mobile {
        width: 100%;
    }
    .error-message {
        color: $error-message-text;
        font-size: 12px;
        margin-top: 5px;
    }
    .vehicle-search-section-title {
        font-size: 24px;
        color: $fds-color-primary;
        line-height: 32px;
        font-weight: 500px;
    }
    .vehicle-search-section-desc {
        font-size: 14px;
        color: $fds-color-primary;
        line-height: 20px;
        font-weight: 500;
    }
    .vehicle-search-input-section {
        margin: 30px 0;
        .vehicle-vin-reg-input-section,
        .vehicle-mileage-input-section {
            margin: 20px 0;
        }
        .fm-dropdown-container {
            .fm-dropdown {
                max-width: none;
            }
        }
        .vin-input-label {
            display: flex;
            .osb-v3-reg-num-or-vin-label {
                width: 90%;
            }
            .vin-reg-info-icon {
                margin-left: 15px;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
        }
        .fm-dropdown-container {
            .fm-dropdown {
                max-width: none;
            }
        }
        .osb-v3-reg-num-or-vin-label,
        .osb-v3-vehicle-mileage-label {
            font-family: $f1-font-regular;
            font-size: 12px;
            font-weight: 300;
            line-height: 16px;
            text-align: left;
            color: $darkgrey;
        }

        .osb-v3-reg-vin-input-wrapper,
        .osb-v3-vehicle-mileage-wrapper {
            .fm-input-container,
            .fm-input {
                max-width: 470px;
                width: 100%;
            }
            .fm-input {
                padding: 0 10px;
            }
        }
        .vin-reg-input-placeholder,
        .mileage-input-placeholder {
            font-size: 12px;
            color: $darkgrey;
            line-height: 16px;
            margin: 5px 0;
        }
        .osb-error-message {
            color: $fm-danger;
            font-size: 12px;
            line-height: 16px;
            margin: 5px 0;
        }
    }
    .cta-control-section {
        display: flex;
        @include mobile {
            flex-direction: column;
            row-gap: 20px;
        }
        .continue-btn {
            margin-right: 10px;
            min-width: 150px;
            @include mobile {
                width: 100%;
            }
            button {
                width: 100%;
                font-size: 14px;
                display: block;
                &::after {
                    background-color: $fm-skyview;
                }
            }
        }
        .do-not-know-details-btn {
            width: 70%;
            @include mobile {
                width: 100%;
            }
            button {
                display: block;
                width: 100%;
                font-size: 14px;
            }
        }
    }
    .loading-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $f1-font-regular;
        font-size: 12px;
        font-weight: bold;
        color: $darkgrey;
        margin-top: 20px;
    }

    .spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-left-color: #09f;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        animation: spin 1s linear infinite;
        margin-right: 8px;
    }

    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}
