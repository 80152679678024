.brand-ford {
    .popular-video-wrapper {
        width: 100%;
        height: auto;
        .popular-video-tile {
            display: flex;
            flex-direction: column;
            box-sizing: border-box;
            transition: 0.5s;
            text-decoration: none;
            align-items: center;
            text-align: left;
            width: 100%;
            vertical-align: middle;
            line-height: 1.875;
            .popular-video-poster {
                width: 100%;
            }

            .video-poster {
                width: 100%;

                &:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: transparent url('../../../../assets/play.png')
                        center center no-repeat;
                    background-size: 100px;

                    @include tablet {
                        background-size: 100px;
                        width: 100%;
                    }

                    @include desktop {
                        background-size: 100px;
                        width: 100%;
                    }
                }

                &:hover:before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    background: transparent url('../../../../assets/play.png')
                        center center no-repeat;
                    background-size: 100px;
                    @include tablet {
                        background-size: 100px;
                        width: 100%;
                    }

                    @include desktop {
                        background-size: 120px;
                        width: 100%;
                    }
                }
            }

            .video-details {
                font-size: 16px;
                height: 60px;
                font-family: $f1-font-light;
                text-transform: uppercase;
                text-align: left;
                width: 100%;
                margin-top: 12px;
                @include desktop {
                    font-size: 16px;
                }
            }

            @include tablet {
                margin: 0;
                display: inline-flex;
                text-align: center;
            }
            .tile-icon {
                margin-top: 1rem;
                height: 1rem;
                width: 1rem;
                color: #324047;
            }

            .content {
                color: #324047;
                font-size: 1rem;
                text-transform: uppercase;
                margin-top: 12px;

                @include tablet {
                    margin: 12px 0 12px;
                }
            }
        }

        .model-video-js {
            margin: auto;
            width: 95%;
            height: 300px;

            @include tablet {
                width: 80%;
                height: 400px;
            }
            @include desktop {
                width: 80%;
                height: 500px;
            }

            .vjs-big-play-button {
                display: block;
            }
        }

        .vjs-has-started {
            .vjs-big-play-button {
                display: none;
            }
        }
        .vjs-paused {
            .vjs-big-play-button {
                display: block;
            }
        }

        .vjs-poster {
            background-size: cover;
            width: 100%;
            position: relative;
        }
        .video-details {
            .video-name {
                text-align: left;
                font-size: 16px;
                font-family: $f1-font-light;
                line-height: 1.5rem;
                @include l-desktop {
                    line-height: 2rem;
                }
            }
        }
    }
}
