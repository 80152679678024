@import '../../../../styles/main.scss';
.osb-v3-dropdown-container {
    font-family: $f1-font-regular;
    color: $fds-color-primary;
    font-size: 14px;
    position: relative;
    width: 100%;
    .fm-input-container {
        max-width: none;
        .fm-input {
            width: 100%;
            height: 40px;
            border: 1px solid $border-neutral;
            border-radius: 10px;
            box-sizing: border-box;
            max-width: none;
        }
    }
    .options-list {
        position: absolute;
        width: 100%;
        border: 1px solid $border-neutral;
        background-color: $white;
        max-height: 300px;
        overflow-y: auto;
        z-index: 1000;
        margin: 0;
        padding: 0;
        list-style: none;
        box-sizing: border-box;
        border-radius: 10px;

        li {
            padding: 10px;
            cursor: pointer;
            border-bottom: 1px solid $border-grey;

            &:last-child {
                border-bottom: none;
            }

            &:hover {
                background-color: $selected-dropdown;
            }

            &.dealer-option {
                background-image: url(../../../../assets/osb-v3/serviceCenter.svg);
                background-repeat: no-repeat;
                background-size: 30px 30px;
                background-position: right 10px center;
            }

            &.location-option {
                background-image: url(../../../../assets/osb-v3/location.svg);
                background-repeat: no-repeat;
                background-size: 30px 30px;
                background-position: right 10px center;
            }

            &.highlighted {
                background-color: $selected-dropdown;
            }
        }

        .checkbox-item {
            background-color: $fds-color-white;
            cursor: default;
            pointer-events: auto;
            padding: 5px 10px;
            .checkbox-container {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                margin: 5px 0;
                label {
                    cursor: pointer;
                    margin-right: 20px;
                    display: flex;
                    align-items: center;
                    max-width: 50%;
                    word-break: break-word;

                    input[type='checkbox'] {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}
