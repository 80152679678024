.brand-ford {
    .how-to-top-text {
        padding-top: 64px;
        height: 216px;
        width: 100%;
        @include desktop {
            padding-top: 104px;
            height: 192px;
            width: 100%;
            margin: 0;
        }

        .how-to-title {
            width: 100%;
            font-family: $f1-font-light;
            font-size: 34px;
            line-height: 44px;
            text-align: center;
            margin: none;
            text-transform: uppercase;
            color: $fds-color-white;
            @include desktop {
                width: 100%;
                font-size: 48px;
                line-height: 58px;
            }
        }

        .how-to-description {
            height: 104px;
            width: 100%;
            color: $fds-color-white;
            font-family: $f1-font-regular;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            margin-top: 24px;

            @include desktop {
                text-align: center;
                height: 52px;
                width: 100%;
                font-family: $f1-font-regular;
            }
            .description-text {
                margin-left: 5%;
                margin-right: 5%;

                @include tablet {
                    margin-left: 15%;
                    margin-right: 15%;
                }

                @include l-desktop {
                    margin-left: 32%;
                    margin-right: 32%;
                }
            }
        }
    }
}
