@import '../../../../../styles/main.scss';
@import '../../../../../styles/breakpoints';
.osb-v3-vehicle-info-scroll-container {
    max-height: 831px;
    overflow-y: auto;
    padding-right: 5px;
    width: 80%;
    @include mobile {
        width: 100%;
        max-height: 1230px;
    }
    margin: 0 auto;

    // WebKit scrollbar styles
    &::-webkit-scrollbar {
        width: 5px; // Width of the scrollbar
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; // Background of the scrollbar track
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #888; // Color of the scrollbar thumb
        border-radius: 10px;

        &:hover {
            background: #555; // Color of the scrollbar thumb on hover
        }
    }
}

.osb-v3-vehicle-info-heading {
    color: #00095b;
    font-family: $f1-font-medium;
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    text-align: center;
    margin: 0 auto 20px auto;
    @include mobile {
        font-size: 32px;
    }
}
.osb-v3-vehicle-info-skeleton-wrapper {
    width: 80%;
    margin: 0 auto;
    @include mobile {
        width: 100%;
    }
}
.osb-v3-vehicle-info-error-container {
    text-align: center;
    padding: 20px;
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
    border-radius: 8px;
    //margin: 20px;
    width: 80%;
    margin: 0 auto;

    .error-icon {
        font-size: 2rem;
        margin-bottom: 10px;
    }

    .error-message {
        h2 {
            margin: 10px 0;
        }

        p {
            margin: 10px 0;
            font-size: 1rem;
        }
    }
}
.osb-v3-add-vehicle-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 92px;
    padding: 0 20px;
    background-color: #f9f9f9;
    width: 80%;
    margin: 0 auto;

    .vehicle-label {
        color: #00095b;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }
    .fm-btn__light {
        @include mobile {
            width: 100%;
            display: block;
        }
    }
}
// Media query for mobile devices
@include mobile {
    .osb-v3-add-vehicle-row {
        flex-direction: column;
        align-items: flex-start;
        height: auto;
        width: 100%;

        .vehicle-label {
            margin-bottom: 10px;
        }
    }
}
.osb-v3-vehicle-step-component {
    // Styles for FMModal to reduce its size
    .fm-modal.vehicle-search-popup {
        .fm-modal-content {
            max-width: 600px;
            width: 90%;
            padding: 20px;
            margin: 48px auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
            .osb-v3-vin-reg-modal-popup {
                .fm-modal-content {
                    max-width: 960px;
                    @include mobile {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }
        .fm-modal-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 16px;
        }

        .fmc-dialog__close {
            width: 24px;
            height: 24px;
            @include mobile {
                width: 20px;
                height: 20px;
            }
            font-size: 16px;
            margin-left: auto;
        }
        .osb-v3-vehicle-search-container {
            margin-top: -59px;
            margin-left: 0;
        }
    }
    .osb-v3-vehicle-info-error-display {
        width: 80%;
        @include mobile {
            width: 100%;
        }
        margin: 0 auto;
    }

    @media (min-width: 992px) {
        .fm-modal.vehicle-search-popup {
            .fm-modal-content {
                max-width: 600px;
            }
        }
    }
}
