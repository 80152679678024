@import '../../../../../styles/main.scss';

.osb-v3-vehicle-info-error-container {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid $fm-danger-subtle;
    background-color: $fm-danger-subtle;
    padding: 20px;
    margin: 10px 0;
    flex-wrap: wrap;
    width: 100%;
    font-family: $f1-font-regular;

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 40px;
        color: $black;
        cursor: pointer;
    }

    .error-icon {
        font-size: 24px;
        margin-right: 30px;
        flex: 0 0 auto;
        align-self: flex-start;
        margin-top: -5px;
    }

    .error-message {
        flex: 1 1 auto;
        text-align: left;
        word-wrap: break-word;
        overflow: hidden;
        max-width: 100%;
        color: $fm-text-grey;

        h2 {
            margin: 0;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 20px;
            @media (max-width: 600px) {
                margin-bottom: 0;
            }
        }

        p {
            margin: 5px 0;
            @media (max-width: 600px) {
                margin: 0 0;
            }
            text-align: left;
        }
    }
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .osb-v3-vehicle-info-error-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 18px;
    }

    .error-icon {
        margin-bottom: 10px;
        margin-right: 0;
    }
}
